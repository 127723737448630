import React from "react";
import { Banner } from "../../components/Banner";
import { Footer } from "../../components/Footer";
import { Navbar } from "../../components/Navbar";
import GlobalStyles from "../../styles/GlobalStyles";
import { AboutUs } from "../../components/AboutUs";
import { Storyline } from "../../components/Storyline";
import { AboutTrainee } from "../../components/AboutTrainee";
import { OccupationArea } from "../../components/OccupantionArea";
import { Requisites } from "../../components/Requisites";
import { Process } from "../../components/Process";
import { SignUp } from "../../components/SignUp";

export const Home = () => {
  return (
    <>
      <GlobalStyles />
      <Navbar />
      <Banner />
      <AboutUs />
      <Storyline />
      <AboutTrainee />
      <OccupationArea />
      <Requisites />
      <Process />
      <SignUp />
      <Footer />
    </>
  );
};
