import React from "react";
import Business from "../../assets/svgs/business.svg";
import Industry from "../../assets/svgs/industry.svg";
import { Container, ContentAreas, Content, Icons, Title } from "./styles";

export const OccupationArea = () => {
  return (
    <Container id="occupation">
      <Title color="#fff">Áreas de atuação</Title>
      <ContentAreas>
        <Content>
          <div className="content-icon">
            <Icons src={Business} alt="Ícone de industry" />
            <div className="content-info">
              <Title color="#fff">Business</Title>
              <p>
                {" "}
                Você que quer fazer parte do coração da nossa empresa! No
                epicentro de todas as operações, você será parte integrante de
                uma equipe que desempenha um papel fundamental no sucesso de
                nossa organização.
              </p>
            </div>
          </div>
        </Content>

        <Content>
          <div className="content-icon">
            <Icons src={Industry} alt="Ícone de industry" />
            <div className="content-info">
              <Title color="#fff">Industry</Title>
              <p>
                Para você que quer mergulhar em como a base do nosso negócio
                funciona, desde a produção até a logística, transformando a
                matéria prima em produtos que geram felicidade.{" "}
              </p>
            </div>
          </div>
        </Content>
      </ContentAreas>
    </Container>
  );
};
