import React from "react";
import IconCheck from "../../assets/svgs/checkicon.svg";
import {
  Check,
  ContentChecklist,
  ContentInfos,
  ContentLeft,
  ContentRight,
  Line,
  PerfilContent,
  Title,
  TopContent,
} from "./styles";
import { Container } from "../../styles/GlobalStyles";

const checklistItems = [
  "Resolução de problemas",
  "Inteligência emocional",
  "Colaboração e apoio",
  "Gestão de pessoas",
  "Pensamento crítico",
  "Liderança nata",
  "Vontade de aprender",
];

export const AboutTrainee = () => {
  return (
    <Container id="about-trainee">
      <TopContent>
        <Title color="#fff">Sobre o</Title>
        <br />

        <div>
          <h4>programa trainee</h4>
          <Line />
        </div>
      </TopContent>

      <ContentInfos>
        <ContentLeft>
          <Title color="#fff">O que é?</Title>
          <p>
            Trainee é um cargo oferecido a jovens prestes a se formarem ou recém
            formados, com um programa de treinamento direcionado para quem
            deseja expandir seus conhecimentos de negócios e gestão de pessoas.
            Ser trainee é interessante para a sua carreira, pois te ajuda a se
            encontrar no mercado de trabalho enquanto ensina a superar tarefas
            árduas do dia a dia de trabalho ao lado de profissionais com
            conhecimento de carreira, com muito a agregar. O The Best Açaí
            procura pessoas com muita vontade de aprender, se conectar e
            realizar, pois depositamos alta confiança e expectativa sobre os
            nossos colaboradores!
          </p>
        </ContentLeft>

        <ContentRight>
          <Title color="#F9A003">Objetivos</Title>

          <p>
            Nosso objetivo é treinar profissionais em determinadas áreas do
            nosso negócio para verificar o nível de conhecimento e habilidades
            de cada um. Buscamos pessoas com alto potencial para se entregar aos
            nossos processos, dispostas a aprender, se desenvolver e,
            eventualmente, entregar resultados.
          </p>
        </ContentRight>
      </ContentInfos>

      <PerfilContent>
        <p>
          DEU CHECK NESSAS CARACTERÍSTICAS? <br />
          ENTÃO O <span>#THEBESTTRAINEE</span> É PARA VOCÊ!
        </p>

        <ContentChecklist>
          {checklistItems.map((item, index) => (
            <div key={index}>
              <Check src={IconCheck} alt="Check icon" />
              <span>{item}</span>
            </div>
          ))}
        </ContentChecklist>
      </PerfilContent>
    </Container>
  );
};
