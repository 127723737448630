import styled from "styled-components";

export const ContentGeneral = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 800px){
        flex-direction: column;
    }
`;

export const ContentInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;

    p {
        color: #FFF;
        text-align: center;
        font-size: 1.1rem;
        font-weight: 900;
        line-height: 181.9%;

        @media screen and (max-width: 800px){
            font-size: 1rem;
            text-align: center;
        }
    }

    span {
        color: #F9A003;
    }

    b {
        font-size: 2.5rem;
        color: #FFF;
        margin-top: 2rem;

        @media screen and (max-width: 800px){
            font-size: 1.5rem;
            text-align: center;
        }
    }

    img {
        width: 100%;
    }

    @media screen and (max-width: 1024px){
        width: 100%;
    }

    a {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 70%;
    height: 5rem;
    font-size: 2rem;
    text-decoration: none;
    margin-top: 4rem;
    background-color: #F9A003;
    border: 0;
    border-radius: 13px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700;

    :hover {
      background-color: #F9A003;
      opacity: 70%;
    }

    @media screen and (max-width: 1300px){
      width: 15rem;
      height: 5rem;
      bottom: 20%;
      left: 65%;
      font-size: 1rem;
    }
  }
`;