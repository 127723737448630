import React from "react";
import HourIcon from "../../assets/svgs/hour-icon.svg";
import GraduateIcon from "../../assets/svgs/requisite-icon.svg";
import { Container, ContentGeneral, ContentIcons, Icon } from "./styles";

export const Requisites = () => {
  return (
    <Container>
      <ContentGeneral>
        <span className="title">Pré-requisitos</span>
        <span>
          PARA FAZER PARTE DO NOSSO PROGRAMA DE TRAINEE, É NECESSÁRIO:{" "}
        </span>

        <ContentIcons>
          <div>
            <Icon src={GraduateIcon} alt="ícone de graduação" />
            <span>
              Estar no último ano de <br />
              graduação ou já ter finalizado
            </span>
          </div>

          <div>
            <Icon src={HourIcon} alt="ícone de relógio" />
            <span>Ter mais de 21 anos</span>
          </div>
        </ContentIcons>
      </ContentGeneral>
    </Container>
  );
};
