import React from "react";
import BannerImg from "../../assets/svgs/banner.svg";
import BannerImgMobile from "../../assets/svgs/backgrounds/mobile-background.svg";
import { Container } from "./styles";

export const Banner: React.FC = () => {
  return (
    <Container>
      {window.outerWidth < 960 ? (
        <img
          className="banner_desktop"
          src={BannerImgMobile}
          alt="Banner do site"
        />
      ) : (
        <img className="banner_desktop" src={BannerImg} alt="Banner do site" />
      )}
      <div>
        <a
          href="https://form.jotform.com/233133425302038"
          target="_blank"
          rel="noopener noreferrer"
          className="inscreva-se-link"
        >
          inscreva-se
        </a>

        <p>Inscrições até dia 31/12/2023</p>
      </div>
    </Container>
  );
};
