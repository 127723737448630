import React from "react";
import ImageI from "../../assets/images/ilustration.png";
import Health from "../../assets/svgs/healthy.svg";
import Alimentation from "../../assets/svgs/alimentation.svg";
import Driver from "../../assets/svgs/drive.svg";
import Clock from "../../assets/svgs/clock.svg";
import Gym from "../../assets/svgs/gym.svg";
import Payment from "../../assets/svgs/payment.svg";
import { Container } from "../../styles/GlobalStyles";
import {
  ContentInfo,
  Title,
  Steps,
  ImageIlustration,
  ContentBenefits,
  ContentBenefitsIcons,
  Icons,
} from "./styles";

const icons = [
  { src: Health, description: "Plano de saúde", alt: "The Best Logo" },
  {
    src: Alimentation,
    description: "Vale alimentação",
    alt: "The Best Acai Logo",
  },
  { src: Driver, description: "Ajuda de custo", alt: "Amadelli Logo" },
  { src: Clock, description: "Assiduidade", alt: "Gracco Logo" },
  { src: Gym, description: "Total pass", alt: "Pe Logo" },
  { src: Payment, description: "Salário R$6.000,00", alt: "Salario" },
];

export const Process = () => {
  return (
    <Container>
      <Title color="#F9A003">Etapas do processo</Title>

      <ContentInfo>
        <Steps>
          <div>
            <span>1</span> <span className="info">Inscrição</span>
          </div>
          <div>
            <span>2</span> <span className="info">Teste online</span>
          </div>
          <div>
            <span>3</span> <span className="info">Pitch pessoal</span>
          </div>
          <div>
            <span>4</span> <span className="info">Dinâmica de grupo</span>
          </div>
          <div>
            <span>5</span> <span className="info">Painel de negócios</span>
          </div>
          <div>
            <span>6</span> <span className="info">Entrevista final</span>
          </div>
        </Steps>

        <ImageIlustration src={ImageI} alt="Trainee 2023 do Grupo The Best" />
      </ContentInfo>

      <ContentBenefits id="benefits">
        <span className="title">Benefícios</span>
        <span>
          Bons resultados geram bons retornos, por isso, a vaga oferece:
        </span>

        <ContentBenefitsIcons>
          {icons.map((icon, index) => (
            <div key={index}>
              <Icons src={icon.src} alt={icon.alt} />
              <span>{icon.description}</span>
            </div>
          ))}
        </ContentBenefitsIcons>
      </ContentBenefits>
    </Container>
  );
};
