import styled from "styled-components";
import BackgroundImage from "../../assets/svgs/backgrounds/white-background.svg";

export const Container = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  height: 80vh;
  min-height: 80vh;
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -6rem;
  z-index: 1;

  span {
    color: #000;
    font-size: 1.1rem;
    font-weight: 900;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 700px) {
    padding: 0 3rem;
    height: 85vh;
  }
`;

export const ContentGeneral = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1300px;
  .title {
    color: #f9a003;
    text-align: start;
    font-size: 2.2rem;
    font-weight: 900;
    width: 100%;
    margin-bottom: 1rem;

    @media screen and (max-width: 700px) {
      font-size: 1.5rem;
    }
    @media screen and (max-width: 500px) {
      font-size: 1rem;
    }
  }

  span {
    @media screen and (max-width: 700px) {
      font-size: 0.8rem;
    }
  }
`;

export const ContentIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 3rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    color: #000;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 900;
    margin-top: 1.3rem;

    @media screen and (max-width: 1024px) {
      font-size: 1rem;
      :last-child {
        margin-bottom: 1rem;
      }
    }

     @media screen and (max-width: 700px) {
      font-size: 0.8rem;
    }
  }
`;

export const Icon = styled.img`
  width: 9rem;
  height: 9rem;
  transition: ease-in-out 0.5s;

  :hover {
    zoom: 1.1;
  }

  @media screen and (max-width: 1024px) {
    width: 6rem;
    height: 6rem;

    :hover {
      width: 7rem;
      height: 7rem;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 4rem;
    height: 4rem;

    :hover {
      width: 5rem;
      height: 5rem;
    }
  }
`;
