import React from "react";
import IconColaboratoes from "../../assets/svgs/icon-1.svg";
import IconStore from "../../assets/svgs/icon-2.svg";
import IconCoin from "../../assets/svgs/icon-3.svg";
import IconFlag from "../../assets/svgs/icon-4.svg";
import IconIndustry from "../../assets/svgs/icon-5.svg";
import { Container, ContentAbout, Icons, IconsContent } from "./styles";

const icons = [
  {
    src: IconColaboratoes,
    description: "2000 colaboradores diretos e indiretos",
    alt: "The Best Logo",
  },
  {
    src: IconStore,
    description: "Mais de 280 lojas abertas;",
    alt: "The Best Acai Logo",
  },
  {
    src: IconCoin,
    description: "Previsão de 350 Milhões de faturamento em 2023; ",
    alt: "Amadelli Logo",
  },
  {
    src: IconFlag,
    description: "Presente em 8 estados;",
    alt: "Gracco Logo",
  },
  {
    src: IconIndustry,
    description: "Mais de 4 Milhões de toneladas de produção.",
    alt: "Pe Logo",
  },
];

export const Storyline = () => {
  return (
    <Container>
      <ContentAbout>
        <h3>
          Tudo isso <br /> em <span>6 anos...</span>
        </h3>

        <IconsContent>
          {icons.map((icon, index) => (
            <div key={index}>
              <Icons src={icon.src} alt={icon.alt} />
              <span>{icon.description}</span>
            </div>
          ))}
        </IconsContent>
      </ContentAbout>
    </Container>
  );
};
