import React, { useState } from "react";

import LogoHeader from "../../assets/svgs/logos/grupothebest.svg";

import {
  CloseIcon,
  ItemNavigation,
  MobileIcon,
  Nav,
  NavbarContainer,
  NavLogo,
  NavMenu,
  OpenIcon,
} from "./styles";

export const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const handleItemClick = () => {
    if (window.innerWidth <= 960) {
      setClick(false);
    }
  };

  return (
    <Nav open={click}>
      <NavbarContainer>
        <NavLogo>
          <img
            src={LogoHeader}
            alt="The Best Açaí logo"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          />
        </NavLogo>

        <MobileIcon open={click} onClick={handleClick}>
          {click ? <CloseIcon /> : <OpenIcon />}
        </MobileIcon>
        <NavMenu open={click}>
          <ItemNavigation>
            <a href="#about-us" onClick={handleItemClick}>
              quem somos
            </a>
          </ItemNavigation>
          <ItemNavigation>
            <a href="#about-trainee" onClick={handleItemClick}>
              sobre o programa
            </a>
          </ItemNavigation>
          <ItemNavigation>
            <a href="#occupation" onClick={handleItemClick}>
              áreas de atuação
            </a>
          </ItemNavigation>
          <ItemNavigation>
            <a href="#benefits" onClick={handleItemClick}>
              benefícios
            </a>
          </ItemNavigation>
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
};
