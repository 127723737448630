import styled, { css } from "styled-components";

export const Title = styled.span<{ color: string }>`
        color: ${({ color }) => color};
        text-align: start;
        font-size: 2.2rem;
        font-weight: 900;

        @media screen and (max-width: 500px){
            font-size: 1.5rem;
        }
`;

export const ContentInfo = styled.div`
    display: flex;
    align-items: center;
`;

export const Steps = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 50px;
    width: 60%;

    div{
        display: flex;
        align-items: center;

        @media screen and (max-width: 750px){
            justify-content: center;
        }
    }

    div:nth-child(2) {
        margin-top: 5rem;

        @media screen and (max-width: 1024px){
            margin-top: 0;
        }
    }
    div:nth-child(4) {
        margin-top: 5rem;

        @media screen and (max-width: 1024px){
            margin-top: 0;
        }

    } 
    div:nth-child(6) {
        margin-top: 5rem;

        @media screen and (max-width: 1024px){
            margin-top: 0;
        }
    } 

    span {
        color: #F9A003;
        text-shadow: -2px 5px 5px rgba(255, 255, 255, 0.88);
        font-size: 6rem;
        font-weight: 900;
        margin-right: 1rem;
        
        @media screen and (max-width: 500px){
            margin-right: 0;
            width: 100%;
            font-size: 3rem;
        }
    }

    .info{
        color: #FFF;
        font-size: 1.8rem;
        font-weight: 900;

        @media screen and (max-width: 500px){
            font-size: 1rem;
        }
    }

    @media screen and (max-width: 1024px) {
       width: 100%;
    }
    @media screen and (max-width: 750px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 1fr);
        align-items: center;
        justify-content: center;
    }
`;

export const ImageIlustration = styled.img`
    width: 40%;

    @media screen and (max-width: 1024px){
        display: none;
    }
`;

export const ContentBenefits = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 3rem;

   .title {
        color: #F9A003;
        text-align: start;
        font-size: 2.2rem;
        font-weight: 900;
        text-transform: capitalize;

        @media screen and (max-width: 500px){
          font-size: 1.5rem;
        }
   }

   span{
    color: #FFF;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 900;
    margin-bottom: 3rem;

    @media screen and (max-width: 750px){
        font-size: 1rem;
    }

      @media screen and (max-width: 500px){
          font-size: .9rem;
          text-transform: capitalize;
    }
   }
`;

export const ContentBenefitsIcons = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 50px;

    div{
        display: flex;
        flex-direction: column;
        align-items: center;

        span{
            margin-top: 1rem;
        }
    }

    @media screen and (max-width: 1024px){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        span{
                margin-top: 0;
            }
    }
    @media screen and (max-width: 750px){
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(6, 1fr);
    }
`;

export const IconStyle = css`
  width: 4rem;
  height: 4rem;
`;

export const Icons = styled.img`
  ${IconStyle}
`;