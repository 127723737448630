import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;

  div {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: end;
    width: 83%;

    @media screen and (max-width: 500px) {
      width: 80%;
    }
  }

  p {
    color: white !important;
    font-size: 1.5rem;
    color: #F9A003 !important;
    margin-bottom: 1rem;
    text-transform: uppercase;

    @media screen and (max-width: 500px) {
      font-size: 1rem;
    }
  }

  img{
    height: 100vh;
    width: 100%;
  }

  a {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 20rem;
    height: 5rem;
    bottom: 15%;
    left: 60%;
    font-size: 2rem;
    font-weight: 700;
    text-decoration: none;
    
    position: absolute;
    background-color: #F9A003;
    border: 0;
    border-radius: 13px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;

    :hover {
      background-color: #F9A003;
      opacity: 70%;
    }

    @media screen and (max-width: 1300px){
      width: 15rem;
      height: 5rem;
      bottom: 20%;
      left: 65%;
      font-size: 1.8rem;
    }

    @media only screen and (max-width: 960px) {
      left: 50%;
      transform: translateX(-50%);
      top: 80%;
    }
    @media only screen and (max-width: 500px) {
      left: 50%;
      transform: translateX(-50%);
      width: 10rem;
      height: 3.5rem;
      font-size: 1.1rem;
      top: 83%;
    }
  }
`;

