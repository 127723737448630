import React from "react";
import Organon from "../../assets/svgs/about-us-image.svg";
import {
  Container,
  ContentAbout,
  LeftContent,
  Organograma,
  RightContent,
} from "./styles";

export const AboutUs = () => {
  return (
    <Container id="about-us">
      <ContentAbout>
        <LeftContent>
          <h2>Quem somos</h2>

          <p>
            O Grupo The Best nasceu em 2017 pela vontade de três jovens
            empreendedores, aqui em Londrina/PR. No início, éramos uma pequena
            semente no mundo dos negócios: um investimento de R$15.000, com uma
            loja simples de poucos metros quadrados na Avenida JK, mas muita
            força de vontade. O tempo passou e estamos cada vez mais próximos do
            sonho de nos tornarmos um grande jardim: atualmente, completando 6
            anos de empresa, conseguimos alcançar 9 estados e mais de 400 lojas,
            dentre elas franquias e filiais, sendo hoje a maior franqueadora de
            self-service de açaís e sorvetes do mundo. Botamos fé que jovens
            como você também têm muito a oferecer e, ao mesmo tempo, aprender no
            meio disso tudo!
          </p>
        </LeftContent>

        <RightContent>
          <Organograma src={Organon} alt="Organograma" />
        </RightContent>
      </ContentAbout>
    </Container>
  );
};
