import styled from "styled-components";
import BackgroundImage from "../../assets/svgs/backgrounds/orange-background-simple.svg";

export const Container = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  height: 96vh;
  min-height: 96vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  box-shadow: 0px 4px 45px 24px rgba(0, 0, 0, 0.25),
    0px -55px 4px 0px rgba(0, 0, 0, 0.25) inset;
  padding: 0 3rem;

  @media only screen and (max-width: 1024px) {
    height: 100vh;
    padding: 0 3rem 4rem;
  }
  @media only screen and (max-width: 400px) {
    height: 80vh;
    padding: 0 2rem 4rem;
  }
`;

export const Title = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  text-align: start;
  font-size: 2.2rem;
  font-weight: 900;
  width: 100%;
  max-width: 1300px;

  @media screen and (max-width: 700px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1rem;
  }
`;

export const ContentAreas = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  margin-top: 3rem;

  .content-icon {
    display: flex;
    align-items: center;
  }

  .content-info {
    width: 80%;
  }

  p {
    color: #000;
    font-size: 1.1rem;
    font-weight: 400;
    width: 80%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    @media screen and (max-width: 800px) {
      margin-top: 1rem;
      font-size: 1rem;
    }
    @media screen and (max-width: 500px) {
      font-size: .9rem;
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }

  @media screen and (max-width: 700px) {
    margin-top: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

export const Icons = styled.img`
  width: 8rem;
  height: 8rem;
  margin-right: 2rem;
  transition: ease-in-out 0.2s;
  :hover {
    width: 9rem;
    height: 9rem;
  }

  @media screen and (max-width: 700px) {
    width: 5rem;
    height: 5rem;
  }
`;
