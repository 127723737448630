import ThebestLogo from "../../assets/svgs/logos/thebest-logo.svg";
import ThebestAcaiLogo from "../../assets/svgs/logos/thebest-acai-logo.svg";
import AmadelliLogo from "../../assets/svgs/logos/amadelli-logo.svg";
import GraccoLogo from "../../assets/svgs/logos/gracco-logo.svg";
import PeLogo from "../../assets/svgs/logos/pe-logo.svg";
import AmafrutaLogo from "../../assets/svgs/logos/amafruta-logo.svg";

import { FooterContainer, ContentFooter, MediaLogos, Logo } from "./styles";

const logos = [
  { src: ThebestLogo, alt: "The Best Logo" },
  { src: ThebestAcaiLogo, alt: "The Best Acai Logo" },
  { src: AmadelliLogo, alt: "Amadelli Logo" },
  { src: GraccoLogo, alt: "Gracco Logo" },
  { src: PeLogo, alt: "Pe Logo" },
  { src: AmafrutaLogo, alt: "Amafruta Logo" },
];

export const Footer = () => {
  return (
    <FooterContainer
      href="https://www.linkedin.com/company/grupothebest/mycompany/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ContentFooter>
        <MediaLogos>
          {logos.map((logo, index) => (
            <Logo key={index} src={logo.src} alt={logo.alt} />
          ))}
        </MediaLogos>
      </ContentFooter>
    </FooterContainer>
  );
};
