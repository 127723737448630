import styled from "styled-components";
import BackgroundImage from "../../assets/svgs/backgrounds/orange-background-simple.svg";

export const Container = styled.div`
  background-image: url(${BackgroundImage});
  background-size: inherit;
  height: 96vh;
  min-height: 96vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  box-shadow: 0px 4px 45px 24px rgba(0, 0, 0, 0.25),
    0px -55px 4px 0px rgba(0, 0, 0, 0.25) inset;

  @media only screen and (max-width: 700px) {
    padding: 3rem;
    height: 100%;
  }

  @media only screen and (max-width: 400px) {
    padding:  5rem 1rem;
  }
`;

export const ContentAbout = styled.div`
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 4rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 0 2rem;
    align-items: baseline;
  }
  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5rem;
  width: 50%;

  h2 {
    color: white;
    font-size: 2.2rem;
    font-weight: 900;
    line-height: normal;

    @media screen and (max-width: 500px) {
      font-size: 1.5rem;
    }
  }

  p {
    margin-top: 1rem;
    color: #000;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;

    overflow: hidden;

    @media screen and (max-width: 500px) {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const RightContent = styled.div`
  display: flex;
  width: 50%;

  @media screen and (max-width: 1024px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
`;

export const Organograma = styled.img`
  width: 40rem;
  @media screen and (max-width: 1024px) {
    width: 20rem;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 500px) {
     width: 15rem;
     margin-bottom: 0;
  }
`;
