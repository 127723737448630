import React from "react";

import Ilustration from "../../assets/images/sign-up.png";
import { Container } from "../../styles/GlobalStyles";

import { ContentGeneral, ContentInfo } from "./styles";

export const SignUp = () => {
  return (
    <Container>
      <ContentGeneral>
        <ContentInfo>
          <p>
            Se encaixa nesse perfil? Queremos <span>você</span> como um futuro
            colaborador no <span>The Best Açaí</span>! <br />
          </p>
          <b>Inscrições abertas dia 13/11</b>

          <a
            href="https://form.jotform.com/233133425302038"
            target="_blank"
            rel="noopener noreferrer"
            className="inscreva-se-link"
          >
            inscreva-se agora
          </a>
        </ContentInfo>
        <ContentInfo>
          <img src={Ilustration} alt="Trainee da rede The Best 2023" />
        </ContentInfo>
      </ContentGeneral>
    </Container>
  );
};
