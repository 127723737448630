import styled, { css } from "styled-components";
import BackgroundImage from "../../assets/svgs/backgrounds/footer-background.svg";

export const FooterContainer = styled.a`
  background-image: url(${BackgroundImage});
  background-size: cover;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 700px) {
    padding: 3rem;
  }
  @media only screen and (max-width: 400px) {
    padding: 0 1rem 1rem;
  }
`;

export const ContentFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const MediaLogos = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 3rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const LogoStyle = css`
  width: 6.5rem;
  height: 6.5rem;

  @media screen and (max-width: 700px) {
    width: 8rem;
    height: 8rem;
  }
`;

export const Logo = styled.img`
  ${LogoStyle}
`;
