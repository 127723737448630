import styled from 'styled-components';

export const Title = styled.span<{ color: string }>`
        color: ${({ color }) => color};
        text-align: start;
        font-size: 2.2rem;
        font-weight: 900;

        @media screen and (max-width: 500px){
            font-size: 1rem;
        }
`;

export const TopContent = styled.div`
    h4 {
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: 900;
        color: #F9A003;
        text-shadow: 2px 2px 0px #FFFFFF, 5px 4px 0px rgba(0,0,0,0.15);

        @media screen and (max-width: 1024px){
            font-size: 3rem;
        }
        @media screen and (max-width: 700px){
            font-size: 2rem;
        }
        @media screen and (max-width: 500px){
            font-size: 1.5rem;
        }
    }

    div {
        display: flex;
        align-items: end;
    }
`;

export const Line = styled.div`
    width: 100%;
    height: 6px;
    background: #FFFFFF; 
    margin: 0 0 1rem;
    z-index: 999;

    @media screen and (max-width: 500px){
           height: 2px;
    }
`;

export const ContentInfos = styled.div`
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    width: 100%;
    
    p{
        color: #FFF;
        font-size: 1.1rem;
        font-weight: 400;
        margin-top: 2rem;

        @media screen and (max-width: 700px){
            flex-direction: column;
            margin-top: 1rem;
            font-size: 1rem;
        }
        @media screen and (max-width: 500px){
            font-size: .9rem;
        }
    }

    @media screen and (max-width: 700px){
        flex-direction: column;
    }
`;

export const ContentLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 5rem;

    @media screen and (max-width: 1024px){
        margin-right: 2rem;
    }

    @media screen and (max-width: 700px){
        width: 100%;
        margin-right: 0;
    }
`;

export const ContentRight = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 5rem;

    @media screen and (max-width: 700px){
        width: 100%;
        margin: 1rem 0 0;
    }
`;

export const PerfilContent = styled.div`
    margin-top: 4rem;

    p{
        margin: 0;
        text-transform: uppercase;
        color: #FFF;
        font-size: 2rem;

        @media screen and (max-width: 700px){
             font-size: 1.5rem;
        }

        @media screen and (max-width: 500px){
             font-size: 1rem;
        }

        span {
            color: #F9A003;
        }
        text-align: center;
    }

    @media screen and (max-width: 1024px){
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    @media screen and (max-width: 500px){
        margin-top: 2rem;
    }
`;

export const ContentChecklist = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 70px;
    margin-top: 3rem;

    span {
        color: #FFF;
        font-size: 2rem;
        font-weight: 900;
        margin-left: 2rem;

        @media screen and (max-width: 700px){
            font-size: 1rem;
        }
    }
    @media screen and (max-width: 700px){
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 30px;
    }
`;

export const Check = styled.img`
    width: 3rem;
    height: 3rem;

        @media screen and (max-width: 700px){
            width: 1.5rem;
            height: 1.5rem;
        }
`;
