import styled, { css } from "styled-components";
import { Menu, CloseOutline } from "../../styles/icons";
interface Props {
  open: boolean;
}

export const Nav = styled.nav<{ open: boolean }>`
  background: #F9A003;
  height: ${({ open }) => (open ? "100vh" : "8vh")};  
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  padding: 1.5rem 4rem;
  overflow: hidden;

  @media only screen and (max-width: 960px) {
    padding: 1.5rem 1rem;
  }
`;

export const NavMenu = styled.ul<Props>`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  width: 70%;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: ${({ open }) => (open ? "100vh" : "0")};  
    position: absolute;
    top: 80px;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    background:  #F9A003;
    overflow: hidden;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const NavLogo = styled.div`
  cursor: pointer;
  display: flex;
  img {
    width: 10rem;

    @media only screen and (max-width: 800px) {
      width: 9rem;
    }
  }
`;

export const MobileIcon = styled.div<Props>`
  display: none;
  @media screen and (max-width: 960px) {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const Navigation = styled.ul`
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: space-between;

  
`;

export const ItemNavigation = styled.li`
    overflow: hidden;

  a{
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 96.4%; 
    cursor: pointer;
    transform: ease-in-out .2s;
    text-decoration: none;
    color: black;
    :hover {
      opacity: 50%;
    }

    @media screen and (max-width: 1024px) {
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 1024px){
    margin-bottom: 3rem;
  }
`;

const icons = css `
  width: 2rem;
  height: 2rem;
  color: black;
`;

export const OpenIcon = styled(Menu)`
  ${icons}
`;
export const CloseIcon = styled(CloseOutline)`
  ${icons}
`;
